import moment from "moment";
import {config} from "./constants";

moment.prototype.dateFormat = function () {
    return this.isValid() ? this.format(config.dateFormat) : '';
};
moment.prototype.dateTimeFormat = function () {
    return this.isValid() ? this.format(config.dateTimeFormat) : '';
};

Array.prototype.insert = function(index) {
    this.splice.apply(this, [index, 0].concat(
        Array.prototype.slice.call(arguments, 1)));
    return this;
};
